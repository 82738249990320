<template>
    <div
        class="asset-avatar"
        :class="{ clickable: isLinkable }"
        :style="{ width: size + 'px', height: size + 'px' }"
        @click="handleClick"
    >
        <div
            class="asset-avatar__img"
            :style="{
                border,
                borderColor,
                boxShadow,
            }"
        >
            <img :src="imageSource" />
        </div>

        <div v-if="showErrorIcon" class="asset-avatar__icon">
            <ErrorIcon
                v-if="showErrorIcon === 'error-generic'"
                :width="iconSize"
                :height="iconSize"
            />
            <BatteryIcon
                v-else-if="showErrorIcon === 'error-battery-low'"
                :width="iconSize"
                :height="iconSize"
            />
            <WarningIcon
                v-else-if="showErrorIcon === 'warning-generic'"
                :width="iconSize"
                :height="iconSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import AssetHelpers from '@/mixins/AssetHelpers'
import BatteryIcon from '@/components/icons/BatteryIcon'
import ErrorIcon from '@/components/icons/ErrorIcon'
import WarningIcon from '@/components/icons/WarningIcon'

export default {
    name: 'AssetAvatar',
    components: {
        BatteryIcon,
        ErrorIcon,
        WarningIcon,
    },
    mixins: [AssetHelpers],
    props: {
        tracker: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            default: 64,
        },
        linkable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('map', ['mapInstance']),
        ...mapGetters('tracker', ['assetsById']),
        showErrorIcon() {
            return this.getError(this.tracker)
        },
        border() {
            const borderSize = Math.round(this.size * 0.045)
            return `${borderSize}px solid ${this.borderColor}`
        },
        borderColor() {
            return this.tracker.asset_details?.color ?? 'black'
        },
        boxShadow() {
            const offset = Math.round(this.size * 0.08)
            return `0px ${offset}px 5px 0px rgba(0, 0, 0, 0.4)`
        },
        iconSize() {
            return Math.round(this.size * 0.3)
        },
        imageSource() {
            return (
                this.tracker.asset_details?.asset_type_marker ??
                this.tracker.asset_type?.marker
            )
        },
        isLinkable() {
            return this.linkable || this.$route.name === 'detail'
        },
    },
    methods: {
        ...mapMutations('tracker', ['setShouldFollowActiveTrackerUpdates']),
        handleClick() {
            if (this.isLinkable) {
                switch (this.$route.name) {
                    case 'detail':
                        this.setShouldFollowActiveTrackerUpdates(true)
                        this.mapInstance.fitBounds([
                            this.assetsById[this.tracker.id]
                                ? [
                                      this.assetsById[this.tracker.id]
                                          .asset_details.position.latitude,
                                      this.assetsById[this.tracker.id]
                                          .asset_details.position.longitude,
                                  ]
                                : [
                                      this.tracker.asset_details.position
                                          .latitude,
                                      this.tracker.asset_details.position
                                          .longitude,
                                  ],
                        ])

                        break
                    case 'sharedAssetDetails':
                        this.mapInstance.fitBounds([
                            [
                                this.tracker.position.lat,
                                this.tracker.position.lng,
                            ],
                        ])
                        break
                    default:
                        this.$router.push({
                            name: 'detail',
                            params: {
                                id: this.tracker.id,
                            },
                        })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.asset-avatar {
    position: relative;
    flex-shrink: 0;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -8%;
        top: -8%;
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    }

    &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 20%;
        border-radius: 50%;
        background-color: #fff;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
</style>
